* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
  font-family: 'Orbitron-Black'; /*a name to be used later*/
  src: url('fonts/Orbitron/Orbitron-Black.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Orbitron-Bold'; /*a name to be used later*/
  src: url('fonts/Orbitron/Orbitron-Bold.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Orbitron-ExtraBold'; /*a name to be used later*/
  src: url('fonts/Orbitron/Orbitron-ExtraBold.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Orbitron-Medium'; /*a name to be used later*/
  src: url('fonts/Orbitron/Orbitron-Medium.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Orbitron-Regular'; /*a name to be used later*/
  src: url('fonts/Orbitron/Orbitron-Regular.ttf'); /*URL to font*/
}

.nameHeader {
  text-align: center;
  line-height: 0.75;

  background: linear-gradient(291deg, #1e3a8a, #3b82f6, #60a5fa, #93c5fd);
  #background-size: 800% 800%;

  -webkit-animation: DallinGradient 20s ease infinite;
  -moz-animation: DallinGradient 20s ease infinite;
  animation: DallinGradient 20s ease infinite;
  font-weight: 600;
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headerName {
  font-family: Orbitron-ExtraBold, system-ui;
  color: rgba(238, 238, 238, 0.51);
  justify-content: center;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100vw;
  align-items: flex-start;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(291deg, #020617, #090d21, #060a46, #0e0062);
  background-size: 800% 800%;

  -webkit-animation: DallinGradient 30s ease infinite;
  -moz-animation: DallinGradient 30s ease infinite;
  animation: DallinGradient 30s ease infinite;

  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  text-align: center;
}

.links {
  display: flex;
  width: 100vw;
  gap: 1rem;
  justify-content: center;
  margin-top: 10px;
}
.link {
  color: rgba(255, 255, 255, 0.16);
  text-decoration: none;
  font-size: MAX(2.5vw, 20px);
  transition: color 0.3s ease, text-shadow 0.3s ease;
  padding: 10px;
}
.link:hover {
  color: #00d9ff;
  text-shadow: 0 0 10px #cdf8f8;
}

@-webkit-keyframes DallinGradient {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}
@-moz-keyframes DallinGradient {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}
@keyframes DallinGradient {
  0%{background-position:2% 0%}
  50%{background-position:99% 100%}
  100%{background-position:2% 0%}
}

.ForestBluePage {
  width: 80vw;
  max-width: 700px;
  text-align: left;
  align-content: center;
  overflow-y: auto;
  height: 100vh;
  padding: 100px 15px;
}

#ForestBlueLogo {
  margin-top: 10px;
}

.ForestBluePage p {
  color: white;
  font-size: 20px;
  margin-top: 15px;
  line-height: 30px;
}

h2 {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 35px;
  color: white;
  font-weight: bolder;
}

h3 {
  margin-top: 20px;
  font-size: 27px;
  color: white;
  font-weight: bold;
}

.About {
  width: 40vw;
  min-width: MIN(400px, 100%);
  display: flex;
  flex-direction: column;
  margin: 10px auto;
}

.backLink {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.standardLink {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.logo-svg {
  margin-left: MIN(10px, 2vw);
  width: 1000px;
  max-width: 90vw;
  height: auto;
  color: #1e3a8a; /* Starting color */
  animation: svgGradient 20s ease infinite;
}

/* Target everything in the SVG */
.logo-svg path,
.logo-svg line,
.logo-svg rect {
  stroke: currentColor;
  fill: currentColor;
}

/* Target the specific classes from your SVG */
.logo-svg .cls-1 {
  fill: none;
  stroke: currentColor;
}

.logo-svg .cls-2 {
  fill: currentColor;
  stroke: none;
}

@keyframes svgGradient {
  0% { color: #000ef6; }
  25% { color: #0066ff; }
  50% { color: #169fff; }
  75% { color: #00d9ff; }
  100% { color: #000ef6; }
}
